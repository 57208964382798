import React, { useState } from "react"
import "./PropertyBanner.scss"
import { Container } from "react-bootstrap"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import PlayVideo from "../PlayVideo/PlayVideo"
import { formTracking } from "../Common/utils"
import NoImage from "../../images/no-image.png"
import GGFXImage from "../../modules/GGFXImage"
import CustomSlider from "../CustomSlider/CustomSlider"

const PropertyBanner = props => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(props?.openGallery ? true : false)
  const [propertyVideo, setPropertyVideo] = useState(false)

  const bannerData = props.PageData
  // const largeImage = bannerData?.images?.strapi_json_value.length>0?bannerData?.images?.strapi_json_value[0]?.url:""
  const largeImage =
    bannerData?.images?.length > 0
      ? bannerData?.images[0]
      : ""

  const smallImage =
    bannerData?.images?.length > 0
      ? bannerData?.images?.slice(1, 3)
      : ""
  
  const getStatus = bannerData?.status === "Off Market"; 
  const offmarketCount = getStatus ? bannerData?.images?.length > 1 ? 1 : bannerData?.images?.length : ""

  const imageCount = offmarketCount ? offmarketCount: bannerData?.images?.length

  const showMapComponent = () => {
    const getId = document.getElementById("map-component")
    getId.scrollIntoView({ behavior: "smooth" })
  }

  const propertyImages = (getStatus && offmarketCount) ? bannerData?.images?.slice(0,1): bannerData?.images;
  var propertyLightImages =
    propertyImages &&
    propertyImages.map(img => (img.srcUrl ? img.srcUrl : img?.url))

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  const { isLargeScreen, isMobile} = useDeviceMedia()

  let propertyVideoUrl = ""

  if (bannerData?.video_tour?.length) {
    propertyVideoUrl = bannerData?.video_tour[0]?.url
  }

  const imagename = "property.images.details"
  const imagename1 = "property.images.detailsmall"

  const handleBrochure=()=>{
    formTracking({
      event_tracking: "Property Detail - Download Brochure",
      form_name: "Property Detail - Download Brochure",
    })
  }

  const handleVideo=()=>{
    formTracking({
      event_tracking: "Video Click",
      form_name: "Video Click",
    })
    setPropertyVideo(!propertyVideo)
  }

  const findOffmarket = bannerData?.status==="Off Market"?"off_market_img":""

  const flagValue = bannerData?.status==="Off Market"?"Off Market Listing":bannerData?.new_development?"Off Plan":
   bannerData?.extra?.completion_status==="Offplan"?"Off Plan | Resale":bannerData?.extra?.completion_status === "Offplan Primary"?"Off Plan":
  (bannerData?.status==="Let"||bannerData?.status==="Sold") ? bannerData?.status:""


  return (
    <>
      <div className="property-banner-wrapper">
        {/* {isMobile&& */}
        <div className="property-image-sections mobile d-block d-md-none">
          <CustomSlider 
            arrowOnMobile
            showOneOnTab
            iconchange
            noDots
            className="prop-details-prop-slider" 
            slidecount={isMobile?1:4}>
              {bannerData?.images?.length>0&&
                bannerData?.images?.map((item,index)=>{
              return(
                <div className="large-image img-zoom">
                    <GGFXImage
                      ImageSrc={item}
                      altText={`mobile-banner`}
                      imagetransforms={props?.PageData?.ggfx_results}
                      renderer="srcSet"
                      imagename={imagename1}
                      strapiID={props?.PageData?.strapi_id}
                      classNames="property-card_image"
                    />

                {
                  bannerData?.video_tour?.length >
                    0 && bannerData?.status!=="Off Market" && index ===0 &&(
                    
                    <div className="play-btn" onClick={() =>handleVideo()}>
                      <i className="icon green-play-btn"/>
                    </div>
                  )
                }
                    {flagValue &&
                      <div className="status-flag">
                        <p>{flagValue}</p>
                      </div>
                    }
                 </div> 
              )
            })
          }
 
        </CustomSlider>
       </div>
       {/* tab slider */}
       <Container>
       <div className="property-image-sections mobile show-tab_only">
          <CustomSlider 
            arrowOnMobile
            showOneOnTab
            iconchange
            noDots
            className="prop-details-prop-slider" 
            slidecount={isMobile?1:4}>
              {bannerData?.images?.length>0&&
                bannerData?.images?.map((item,index)=>{
              return(
                <div className="large-image img-zoom">
                    <GGFXImage
                      ImageSrc={item}
                      altText={`mobile-banner`}
                      imagetransforms={props?.PageData?.ggfx_results}
                      renderer="srcSet"
                      imagename={imagename}
                      strapiID={props?.PageData?.strapi_id}
                      classNames="property-card_image"
                    />
                     {
                      bannerData?.video_tour?.length >
                        0 && bannerData?.status!=="Off Market" && index ===0 &&(
                        
                        <div className="play-btn" onClick={() =>handleVideo()}>
                          <i className="icon green-play-btn"/>
                        </div>
                      )
                    }
                    {flagValue &&
                      <div className="status-flag">
                        <p>{flagValue}</p>
                      </div>
                    }
                 </div> 
              )
            })
          }
 
        </CustomSlider>
       </div>
       </Container>
        {/* } */}
        <Container>
          <div className="property-image-sections large-device">
            {/* {!isMobile&& */}
            <div className="large-image img-zoom d-none d-xl-block">
              <button
              className="btn-image_wrapper"
                // href="javascript:void(0)"
                onClick={e => openPropertyImage(e, 0)}
              >
                {largeImage?.url?
                  <GGFXImage
                    ImageSrc={largeImage}
                    altText={`${bannerData?.title}`}
                    imagetransforms={props?.PageData?.ggfx_results}
                    renderer="srcSet"
                    imagename={imagename}
                    strapiID={props?.PageData?.strapi_id}
                    classNames="property-card_image"
                    lazyLoading="eager"
                  />
                :
                <img src={NoImage}  classNames="property-card_image" alt="No Image" />
                }

              {flagValue &&
                <div className="status-flag">
                  <p>{flagValue}</p>
                </div>
                }

              </button>
              {!isLargeScreen&&
              <div className="property-image-containers">
                {bannerData?.images &&
                  bannerData?.images?.length > 0 && (
                    <div className="view-images-property icon-text">
                      <p className="light-box-btn"
                        // href="javascript:void(0)"
                        onClick={e => openPropertyImage(e, 0)}
                      >
                        <i className="icon gallery-black-icon"></i>{" "}
                        <span>1/{imageCount} Photos</span>
                      </p>
                    </div>
                  )}
                <div className="property-view-map icon-text">
                <p className="light-box-btn"
                    // href="javascript:void(0)"
                    onClick={() => showMapComponent()}
                  >
                    <i className="icon map-black-icon" /> <span>Map</span>
                  </p>
                </div>
                {bannerData?.brochure?.length > 0 && (
                  <div className="brochure icon-text">
                    <a
                    onClick={handleBrochure}
                      href={
                        bannerData?.brochure[0]?.srcUrl
                      }
                      target="_blank"
                    >
                      <i className="icon brochure-black-icon" />{" "}
                      <span>Brochure</span>
                    </a>
                  </div>
                )}
              </div>
              }
            </div>
              {/* } */}

            {/* {!isLargeScreen && ( */}
              <div className="small-image d-none d-xl-block">
                {smallImage &&
                  smallImage.map((image, index) => (
                    <button
                      // href="javascript:void(0)"
                      onClick={e => openPropertyImage(e, index + 1)}
                      className={`img-zoom ${findOffmarket}-${index}`}
                      key={image?.url}
                    >
                      {(image?.url || image?.srcUrl)?
                        <GGFXImage
                          ImageSrc={image}
                          altText={`${bannerData?.title}`}
                          imagetransforms={props?.PageData?.ggfx_results}
                          renderer="srcSet"
                          imagename={imagename1}
                          strapiID={props?.PageData?.strapi_id}
                          classNames="property-card_image"
                          lazyLoading="eager"
                        />
                      :
                      <img src={NoImage} classNames="property-card_image" alt="No Image" />
                    }
                      {index === 0 &&
                        bannerData?.video_tour?.length >
                          0 && bannerData?.status!=="Off Market" &&(
                          
                          <div className="play-btn" onClick={() =>handleVideo()}>
                            <i className="icon green-play-btn"/>
                            </div>
                        )}
                    </button>
                  ))}
              </div>
            {/* )} */}
          </div>
          {/* {isLargeScreen&& */}
          <div className="property-image-containers-mobile d-flex d-xl-none">
                {bannerData?.images &&
                  bannerData?.images?.length > 0 && (
                    <div className="view-images-property icon-text">
                      <p className="light-box-btn"
                        onClick={e => openPropertyImage(e, 0)}
                      >
                        <i className="icon gallery-black-icon"></i>{" "}
                        <span>1/{imageCount} Photos</span>
                      </p>
                    </div>
                  )}
                <div className="property-view-map icon-text">
                <p className="light-box-btn"
                    onClick={() => showMapComponent()}
                  >
                    <i className="icon map-black-icon" /> <span>Map</span>
                  </p>
                </div>
                {bannerData?.brochure?.length > 0 && (
                  <div className="brochure icon-text">
                    <a
                    onClick={handleBrochure}
                      href={
                        bannerData?.brochure[0]?.srcUrl
                      }
                      target="_blank"
                    >
                      <i className="icon brochure-black-icon" />{" "}
                      <span>Brochure</span>
                    </a>
                  </div>
                )}
          </div>
          {/* } */}
        </Container>
      </div>
      {isOpen && propertyLightImages?.length>0&& (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={
            propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
          }
          prevSrc={
            propertyLightImages[
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}

      {/* video tour */}
      <PlayVideo
        isOpen={propertyVideo}
        stopPlay={setPropertyVideo}
        videoId=""
        isCloseFunction={setPropertyVideo}
        videourl={propertyVideoUrl}
      />
    </>
  )
}

export default PropertyBanner