import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import {filterNumber, numberFormat, rentalYieldResult } from "../../components/Common/utils"
import "./RentalYieldCalculator.scss"

const RentalYieldCalculator = ({ data,pageName }) => {
  const landingPagePrice = pageName ? 2500000:""
  const suffix = "AED"
  const monthlyRent = pageName ? landingPagePrice *0.08 : data?.price*0.08
  const [propertyPrice, setPropertyPrice] = useState(landingPagePrice ? landingPagePrice : data?.price)
  const [serviceCharge, setServiceCharge] = useState(0)
  const [additionalCharge, setAdditionalCharge] = useState(0)
  const [rentalPrice, setRentalPrice] = useState(numberFormat(monthlyRent))
  const [netRent, setNetRent] = useState(numberFormat(0))
  const [rentalYield,setRentalYield]=useState(0)

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPropertyPrice(numberFormat(val))
    // setRentalPrice(numberFormat(val*0.08))
  }

  const handleService = event => {
    let val = filterNumber(event.target.value)
    setServiceCharge(numberFormat(val))
  }
  const handleAdditional = event => {
    let val = filterNumber(event.target.value)
    setAdditionalCharge(numberFormat(val))
  }

  const handleRentalprice = event => {
    let d = filterNumber(event.target.value)
    setRentalPrice(numberFormat(d))
  }

  useEffect(() => {
    if (data?.price) {
      setPropertyPrice(numberFormat(data.price))
    }
    else if(landingPagePrice){
      setPropertyPrice(numberFormat(landingPagePrice))
    }
  }, [data,pageName])

  const getResult = (propertyPrice, rentalPrice) => {
    setNetRent(
      numberFormat(
        filterNumber(rentalPrice) -
          filterNumber(serviceCharge) -
          filterNumber(additionalCharge)
      )
    )
    const netROIResult = rentalYieldResult(filterNumber(propertyPrice), (filterNumber(rentalPrice) - filterNumber(serviceCharge) - filterNumber(additionalCharge)))

    // const netROI=numberFormat((filterNumber(rentalPrice)/filterNumber(propertyPrice))*100);
    setRentalYield(netROIResult?.toFixed(2))
  }

  useEffect(() => {
    getResult(propertyPrice, rentalPrice)
  }, [propertyPrice, rentalPrice, serviceCharge, additionalCharge])

  return (
    <div className="rental-yieldcalc-wrapper" id="rental-yield_calc">
      <div className="rental-calc-section">
        <h3 className="rental-yield-title" >Rental Yield Calculator</h3>
        <p className="rentail-yield-desc">
          Calculate the gross and net rental yields on a property.
        </p>
        <div className="rentalyield-calc-form">
          <Form className="rentalyield-form-section">
            <Form.Group controlId="formFile" className="form-text-field">
              <Form.Label>Property Price</Form.Label>
              <Form.Control
                type="text"
                // placeholder="AED"
                value={propertyPrice}
                onChange={e => handlePrice(e)}
              />
              <span className="price-suffix">{suffix}</span>
            </Form.Group>
            <Form.Group controlId="formFile" className="form-text-field">
              <Form.Label>Annual services charges</Form.Label>
              <Form.Control
                type="text"
                // placeholder="AED"
                value={serviceCharge}
                onChange={handleService}
              />
              <span className="price-suffix">{suffix}</span>
            </Form.Group>
            <Form.Group controlId="formFile" className="form-text-field">
              <Form.Label>Additional charges</Form.Label>
              <Form.Control
                type="text"
                // placeholder="AED"
                value={additionalCharge}
                onChange={handleAdditional}
              />
              <span className="price-suffix">{suffix}</span>
            </Form.Group>
            <Form.Group controlId="formFile" className="form-text-field">
              <Form.Label>Annual rental price</Form.Label>
              <Form.Control
                type="text"
                // placeholder="AED"
                value={rentalPrice}
                onChange={handleRentalprice}
              />
              <span className="price-suffix">{suffix}</span>
            </Form.Group>
          </Form>
        </div>
        <div className="calculation-section">
        <div className="rental-rapayments">
          <p>Net Rent</p>
          <h3>AED {netRent}</h3>
        </div>
        <div className="net-roi">
          <p>NET ROI</p>
          <h3>{rentalYield}%</h3>
        </div>
        </div>
      </div>
    </div>
  )
}

export default RentalYieldCalculator
