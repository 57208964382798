import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import {
  calculateMonthlyPayment,
  filterNumber,
  formTracking,
  numberFormat,
  numericWithDecimal,
} from "../Common/utils"
import "./MortgageCalculator.scss"
import FormFields from "../../../static/forms/property-mortgage.json"
import DefaultForm from "../forms/default-form-module"

const MortgageCalculator = props => {
  const [show, setShow] = useState(false)
  const [showCost, setShowCost] = useState(false)
  const propertyData = props?.propertyInfo
  const isLand = propertyData?.building?.length > 0 && propertyData?.building[0] === "Land"
  const landFee = isLand ? 430 : 580

  const [propertyPrice, setPropertyPrice] = useState(
    numberFormat(
      props.landingPage
        ? filterNumber("1,000,000")
        : propertyData?.price
        ? propertyData?.price
        : ""
    )
  )
  const [deposit, setDeposit] = useState(
    numberFormat(Math.round(filterNumber(propertyPrice) * 0.2))
  )
  const [mortgagePeriod, setMortgagePeriod] = useState(25)
  const [intrestRate, setIntrestRate] = useState(props.landingPage ? 5 : 5)
  const [periodErr, setPeriodErr] = useState(false)
  const [depositErr, setDepositErr] = useState(false)
  const [intrestErr, setIntrestErr] = useState(false)

  //---------cost and fees state----------

  const departmentCalc = numberFormat(Math.round(filterNumber(propertyPrice) * 0.04))
  const loanCalc = filterNumber(propertyPrice) - filterNumber(deposit)
  const agencyCalc1 = Math.round((filterNumber(propertyPrice)*0.02)*0.05) 
  const agencyCalc2 = Math.round(filterNumber(propertyPrice)*0.02)

  const [departmentFee, setDepartmentFee] = useState(filterNumber(departmentCalc) + landFee)
  const [officeFee, setOfficeFee] = useState(Math.round(propertyData?.price < 500000? 2100:4200))
  const [registration, setRegistration] = useState(Math.round(loanCalc * 0.0025) + 290);
  const [agencyFee, setAgencyFee] = useState(agencyCalc1+agencyCalc2)
  const [bankFee, setBankFee] = useState(filterNumber(loanCalc)*0.01);
  const [valuation, setValuation] = useState(3000); 
  const [conveyancingFee, setConveyancingFee] = useState(8400);

  const purchaseResult = departmentFee + officeFee + registration + agencyFee + bankFee + valuation + conveyancingFee
  const [totalPurchase, setTotalPurchase] = useState(purchaseResult)
  const [upFront, setUpFront] = useState(totalPurchase + filterNumber(deposit))

  useEffect(()=>{
    setRegistration(Math.round((loanCalc * 0.0025) + 290))
    setAgencyFee(agencyCalc1+agencyCalc2)
    setBankFee(Math.round(filterNumber(loanCalc)*0.01))
    setTotalPurchase(Math.round(departmentFee + officeFee + registration + agencyFee + bankFee + valuation + conveyancingFee))
    setUpFront(Math.round(purchaseResult + filterNumber(deposit)))
  },[loanCalc,agencyFee,registration,bankFee])

  const handleChange = event => {
    if (event.target.name === "property_price") {
      let val = filterNumber(event.target.value)
      setPropertyPrice(numberFormat(val))
      setDeposit(numberFormat(Math.round(val * 0.2)))
      setDepartmentFee(Math.round(filterNumber(val) * 0.04) + 580)
      setOfficeFee(Math.round(filterNumber(val) < 500000? 2100:4200))

      
    } else if (event.target.name === "mortgage_period") {
      let duration = filterNumber(event.target.value)
      if (duration > 30 || duration < 5) {
        setPeriodErr("Mortgage period should be between 5 to 30")
        setMortgagePeriod(numberFormat(duration))
      } else {
        setMortgagePeriod(numberFormat(duration))
        setPeriodErr(false)
      }
    } else if (event.target.name === "deposit") {
      let dep = filterNumber(event.target.value)
      if (dep >= filterNumber(propertyPrice)) {
        setDepositErr(
          "Deposit should be less than property price and greater than zero"
        )
        return
      } else {
        setDepositErr(false)
        setDeposit(numberFormat(dep))
      }
    } else if (event.target.name === "intrest_rate") {
      let intrest = numericWithDecimal(event.target.value)
      if (intrest > 10 || intrest < 1) {
        setIntrestErr("Intrest Rate should be between 1% to 10%")
        setIntrestRate(intrest)
      } else {
        setIntrestRate(intrest)
        setIntrestErr(false)
      }
    }
  }

  // const loanAmount = filterNumber(propertyPrice) - filterNumber(deposit)
  const repayments = calculateMonthlyPayment(
    filterNumber(propertyPrice),
    numericWithDecimal(parseFloat(intrestRate)),
    filterNumber(deposit),
    filterNumber(mortgagePeriod)
  )

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        "mortgage_repayments",
        numberFormat(Math.round(repayments))
      )
    }
    formTracking({
      event_tracking: "Mortage calculator",
      form_name: "Mortage calculator",
    })
  }, [repayments])

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleCostSection = () => {
    setShowCost(true)
  }

  const handleCalcClose = () =>{
    setShowCost(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  // const totalLoan = filterNumber(repayments) * (mortgagePeriod * 12)
  // const totalInterestPaid = totalLoan - filterNumber(loanCalc)
  return (
    <div className="mortage-calc" id="mortgage-calculator">
      <div className="property-horizontal-line"></div>
      <h2 className="mortgage-title">Mortgage Calculator</h2>
      <p className="sub-title-mortgage">
        Estimate your monthly mortgage payments
      </p>
      <div className="mortgage-calc-form">
        <Form className="mortgage-form-section">
          <Form.Group controlId="formPropertyPrice" className="form-text-field">
            <Form.Label>Property Price</Form.Label>
            <Form.Control
              type="text"
              name="property_price"
              value={propertyPrice}
              onChange={e => handleChange(e)}
            />
            {intrestErr || periodErr || depositErr ? (
              ""
            ) : (
              <span className="price-name">AED</span>
            )}
          </Form.Group>
          <Form.Group controlId="formDeposit" className="form-text-field">
            <Form.Label>Deposit</Form.Label>
            <Form.Control
              type="text"
              name="deposit"
              value={deposit}
              onChange={e => handleChange(e)}
            />
            {intrestErr || periodErr || depositErr ? (
              ""
            ) : (
              <span className="price-name">AED</span>
            )}
            {depositErr && <p className="mortgage-err-msg">{depositErr}</p>}
          </Form.Group>
          <Form.Group controlId="formMortgagePeriod" className="form-text-field">
            <Form.Label>Mortgage Period</Form.Label>
            <Form.Control
              type="text"
              name="mortgage_period"
              value={mortgagePeriod}
              onChange={e => handleChange(e)}
            />
            {intrestErr || periodErr || depositErr ? (
              ""
            ) : (
              <span className="price-name">Years</span>
            )}
            {periodErr && <p className="mortgage-err-msg">{periodErr}</p>}
          </Form.Group>

          <Form.Group controlId="formInterestRate" className="form-text-field">
            <Form.Label>Interest Rate</Form.Label>
            <Form.Control
              type="text"
              name="intrest_rate"
              value={numericWithDecimal(intrestRate)}
              onChange={e => handleChange(e)}
            />
            {intrestErr || periodErr || depositErr ? (
              ""
            ) : (
              <span className="price-name">%</span>
            )}
            {intrestErr && <p className="mortgage-err-msg">{intrestErr}</p>}
          </Form.Group>
        </Form>
      </div>
      <div className="mortgage-repayments">
        <div className="repayment-result">
          <p className="repayments-month">Monthly Payment</p>
          <h3>AED {numberFormat(Math.round(repayments))}</h3>
        </div>
        {/* <div className="total-loan-amount">
          <p className="total-loan-text">Total Loan Amount</p>
          <h3>AED {numberFormat(Math.round(totalLoan))}</h3>
        </div>
        <div className="total-intrest-amount">
          <p className="total-intrest-text">Total Interest Amount</p>
          <h3>AED {numberFormat(Math.round(totalInterestPaid))}</h3>
        </div> */}
        {!props.landingPage && (
          <div className="preapprove-block">
            {/* <Link to="/other-services/mortgages/" className="mortgage-fees"> */}
              <button  onClick={handleModal} className="button button-filled-green">
                Get pre-approved
              </button>
              <Modal
                show={show}
                // onHide={handleClose}
                backdrop="static"
                centered
                dialogClassName="modal-popup-form"      >
                <Modal.Body>
                  <div className="popup-form-wrapper">
                    <div className="close-modal" onClick={() => handleClose()}>
                      <i className="icon black-cancel-icon"></i>
                    </div>
                    <div>
                      <DefaultForm
                        fields={FormFields}
                        formTitle="Mortgage Enquiry"
                        sourceUrl={pageurl}
                        isPropertyMortgage
                      />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            {/* </Link> */}
            {/* <div className="whats-app-block">
              <a
                className="whatsapp-link"
                href="https://wa.me/97143025800?text=Hi, I've come across the haus and haus Group and would like further information from you"
                target="_blank"
              >
                WhatsApp our team on <span>+97143025800</span>
              </a>
            </div> */}
          </div>
        )}

      </div>

            <div className="mortage-cost-section">
            <button onClick={()=>handleCostSection()} className="mortgage-fees-btn">
              View Mortgage Costs and Fees
            </button>

            <Modal
                show={showCost}
                // onHide={handleClose}
                backdrop="static"
                centered
                dialogClassName="modal-popup-form mortage-calc-modal">
               <Modal.Body>
                  <div className="popup-form-wrapper">
                    <div className="close-modal" onClick={() => handleCalcClose()}>
                      <i className="icon black-cancel-icon"></i>
                    </div>
                      <div className={"cost-and-fees"}>
                        <h4>Mortgage Costs and Fees</h4>
                        <div className="cost-and-fees-wrapper">
                          <div>
                            <p className="cost_fees-text">Land Department Fee
                              <p className="icon-text-folder-mortgage">
                              <i className="icon icon-information1"></i>
                              <span className="tookit-text">
                              <i className="icon primary-tri-icon" />
                                4% of purchase price + 580
                                </span>
                              </p>
                            </p>
                            <p className="cost_fees-result">{numberFormat(departmentFee)} AED</p>
                          </div>
                          <div>
                            <p className="cost_fees-text">Trustee Office Fee incl. 5% VAT
                            <p className="icon-text-folder-mortgage">
                              <i className="icon icon-information1"></i>
                              <span className="tookit-text long-text-tag">
                              <i className="icon primary-tri-icon" />
                              If the purchase price is below 500k, the registration fee is 2k + 5% VAT. For 500k and above, the fee is 4k + 5% VAT.
                                </span>
                              </p>
                            </p>
                            <p className="cost_fees-result">{numberFormat(officeFee)} AED</p>

                          </div>
                          <div>
                            <p className="cost_fees-text">Mortgage Registration
                              <p className="icon-text-folder-mortgage">
                              <i className="icon icon-information1"></i>
                              <span className="tookit-text">
                              <i className="icon primary-tri-icon" />
                              0.25% of loan + AED 290
                                </span>
                              </p>
                            </p>
                            <p className="cost_fees-result">{numberFormat(registration)} AED</p>

                          </div>
                          <div>
                            <p className="cost_fees-text">Real Estate Agency Fee incl. 5% VAT
                            <p className="icon-text-folder-mortgage">
                              <i className="icon icon-information1"></i>
                              <span className="tookit-text">
                              <i className="icon primary-tri-icon" />
                                2% of purchase price + 5% VAT
                                </span>
                              </p>
                            </p>
                            <p className="cost_fees-result">{numberFormat(agencyFee)} AED</p>

                          </div>
                          <div>
                            <p className="cost_fees-text">Bank Arrangement Fee
                              <p className="icon-text-folder-mortgage">
                              <i className="icon icon-information1"></i>
                              <span className="tookit-text long-text-tag">
                              <i className="icon primary-tri-icon" />
                                  Upto 1% of loan amount to be agreed directly with lender
                                </span>
                              </p>
                            </p>
                            <p className="cost_fees-result">{numberFormat(bankFee)} AED</p>

                          </div>
                          <div>
                            <p className="cost_fees-text">Valuation
                            <p className="icon-text-folder-mortgage">
                              <i className="icon icon-information1"></i>
                              <span className="tookit-text long-text-tag">
                              <i className="icon primary-tri-icon" />
                                Depending on bank, please discuss with your lender
                                </span>
                              </p>
                            </p>
                            <p className="cost_fees-result">{numberFormat(valuation)} AED</p>

                          </div>
                          <div>
                          <p className="cost_fees-text">Conveyancing Fee incl. 5% VAT
                            <p className="icon-text-folder-mortgage">
                              <i className="icon icon-information1"></i>
                              <span className="tookit-text">
                              <i className="icon primary-tri-icon" />
                                Sales progression
                                </span>
                              </p>
                            </p>
                            <p className="cost_fees-result">{numberFormat(conveyancingFee)} AED</p>
                          </div>
                        </div>
                      <div className="total-cost-sections">  
                        <div>
                          <p className="cost_fees-text total-cost">TOTAL PURCHASE COSTS</p>
                          <p className="cost_fees-result total-cost">{numberFormat(totalPurchase)} AED</p>
                        </div>

                        <div>
                          <p className="cost_fees-text total-cost">TOTAL REQUIRED UPFRONT
                          <p className="icon-text-folder-mortgage">
                              <i className="icon icon-information"></i>
                              <span className="tookit-text long-text-tag">
                                <i className="icon primary-tri-icon"/>
                                  Deposit + total purchase costs
                              </span>
                              </p>
                          </p>
                          <p className="cost_fees-result total-cost">{numberFormat(upFront)} AED</p>
                        </div>
                      </div>

                      <p className="disclaimer-text">
                      *This calculator is for illustration purposes only and should be used 
                      as a guide, the rates shown are subject to change. 
                      A full breakdown of charges will be given at the time of your mortgage offer.
                    </p>

                    <p className="more-disclaimer">
                      **These charges and fees are based on industry averages and should be confirmed
                      with your Real Estate agents for exact figures,
                      haus & haus Real Estate Broker accept no liability for indicative illustrations.
                    </p>
                      </div>
                </div>
                </Modal.Body>
              </Modal>
              {!props.landingPage &&
              <div className="horizontal-line"></div>
              }
            </div>
    </div>
  )
}

export default MortgageCalculator