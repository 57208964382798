import { Link } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/book_a_viewing.json"
import NoImage from "../../images/no-image.png"
import DefaultForm from "../forms/default-form-module"
import "./PropertyContactModule.scss"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import { teamURL } from "../../lib/urls"
import GGFXImage from "../../modules/GGFXImage"




const PropertyContactModule = props => {

  const [show, setShow] = useState(false)
  const [copied ,setCopied]  = useState(false);

  const negotiator=props.negotiator
  const negotiatorUrl = negotiator?.slug

  const negoImage=props.negoImage

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const propImage=props.propertyInfo?.images?.length>0?props.propertyInfo?.images[0]?.url:""
  const text=`Hi, I saw this property on haus & haus ${pageurl} and would like to know more.`

  const copyLink =(link)=>{
    navigator.clipboard.writeText(link)
    .then(()=>{
      setCopied(true)
      setTimeout(()=>{
        setCopied(false)
      },1500)
    })
    .catch(err=>console.error("Error", err))
  }
  const language_speaks =negotiator?.Languages_Spoken?.strapi_json_value?.length>0?
  negotiator?.Languages_Spoken?.strapi_json_value?.join(", "):""

  const imagename="team.image.property_team_image"
  const negoUrl = negotiatorUrl? `${teamURL}${negotiatorUrl}/`:"/contact/"
  const negoWhatsapp = negotiator?.whatsapp?negotiator.whatsapp?.replace(/[^A-Z0-9]/ig, ""):"97143025800"

  return (
    <div className="property-contact-component">
      <div className="property-contact-content">
        
        <div className="property-team-desktop">
        {props.image_url&&
          <div className="property-team-data">
             {negoImage?.url&&
            <Link to={negoUrl}>
            <div className="image-section">
             
              <GGFXImage
              ImageSrc={negoImage}
              altText={`${negotiator?.name}`}
              imagetransforms={negotiator?.ggfx_results}
              renderer="srcSet"
              imagename={imagename}
              strapiID={negotiator?.strapi_id}
            />
            </div>
            </Link>
            }
            <Link to={negoUrl}>
            <div className="property-team-info">
              <h5 className="name">
                {props.name}
              </h5>
              {negotiator?.designation&&
              <p className="designation">{negotiator?.designation}</p>
              }
              {language_speaks&&
               <p className="language-speak">Speaks {language_speaks}</p>
              }
            </div>
            </Link>
            
          </div>
          }

        <div className="cta-holder">
          <div className="cta-secondary">
            
            <a href={`tel:${negotiator?.phone?negotiator?.phone:"97143025800"}`}>
              <button className="button button-filled-green">
                {" "}
                <i className="icon prop-mobile-outline" /> <span>Call</span>
              </button>
            </a>
            
          <a className="whatsapp-link" target="_blank" href={`https://wa.me/${negoWhatsapp}?text=${encodeURIComponent(text)}`}>
            <button className="button button-filled-green">
              {" "}
              <i className="icon black-whatsapp-new" />
              <span>WhatsApp</span>
            </button>
          </a>
          
          </div>
            {(props.propertyInfo?.status==="Sold" || props.propertyInfo?.status==="Off Market")?
              <></>
              :
              // <a href="javascript:void(0);" role="button" className="viewing-cta" onClick={handleModal}>
                <button onClick={handleModal} className="button button-outline-white viewing-cta ">
                  {" "}
                  <i className="icon book-viewing" /> <span>Book a Viewing</span>
                </button>
              // </a>
            }
              {
                 <Modal
                 show={show}
                 // onHide={handleClose}
                 backdrop="static"
                 centered
                 dialogClassName="modal-popup-form book_viewing-form_"
               >
                 <Modal.Body>
                   <div className="popup-form-wrapper">
                     <div className="close-modal" onClick={() => handleClose()}>
                       <i className="icon black-cancel-icon"></i>
                     </div>
                     <div>
                       <DefaultForm 
                       fields={FormFields} 
                       sourceUrl={pageurl}
                       prop_url={pageurl}
                       prop_img_url={propImage} 
                       negoEmail={negotiator?.email}
                       prop_address={props.propertyInfo?.display_address} 
                       formTitle="Book a Viewing"/>
                     </div>
                   </div>
                 </Modal.Body>
               </Modal>
              }
        </div>

        <div className="share-container_prop">  
          <div className="share-module-property">
            <i className="icon share-prop-icon"/><p>Share this Listing</p>
          </div>
          <div className="social-media-links">
              <div>
                <WhatsappShareButton url={pageurl}>
                   <span><i className="icon social-whatsapp"/></span>
                    <span className="share-text">Share on WhatsApp</span>
                </WhatsappShareButton>
              </div>
              <div>
              <FacebookShareButton url={pageurl}>
                <span><i className="icon social-fb"/></span><span className="share-text">Share on Facebook</span>
               </FacebookShareButton> 
              </div>
              <div>
                <TwitterShareButton url={pageurl}>
                <span><i className="icon social-twitter"/></span><span className="share-text">Share on Twitter</span>
                </TwitterShareButton>
              </div>
              <div className="copy-link-holder" onClick={()=>copyLink(pageurl)}>
                <span><i className="icon social-copy"/></span><span className="share-text">Copy URL link</span>
              </div>
              {copied&&
                <button className="copied-btn">Copied!</button>
              }
          </div>
        </div>

          
        </div>
        <div className="property-val-desktop">
          <div className="val-card-wrapper">
            <h5>List your home with us</h5>
            <p>
            Get an approximate valuation in a matter of minutes.
            </p>
            <Link to="/property-valuation/">
            <button className="button button-outline-black">
              Book a Valuation
            </button>
            </Link>
          </div>
        </div>
      </div>
     
    </div>
  )
}

export default PropertyContactModule
