import { Link } from "gatsby"
import parse from "html-react-parser"
import React,{useEffect,useState} from "react"
import { Container, Modal } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { capitalize, numberFormat } from "../Common/utils"
import MortgageCalculator from "../MortgageCalculator/MortgageCalculator"
import PropertyAreaCards from "../PropertyAreaCard/PropertyAreaCards"
import PropertyContactModule from "../PropertyContactModule/PropertyContactModule"
import Map from "../PropertyMap/Map"
import SimilarSoldProperties from "../SimilarSoldProperties/SimilarSoldProperties"
import "./PropertyInformation.scss"
import FormFields from "../../../static/forms/property-mortgage.json"
import DefaultForm from "../forms/default-form-module"
import { teamURL } from "../../lib/urls"
import GGFXImage from "../../modules/GGFXImage"
import RentalYieldCalculator from "../RentalYieldCalculator/RentalYieldCalculator"

const PropertyInformation = props => {
  const propertyInfo = props.PageData
  const [show, setShow] = useState(false)

  const [renderComponent, setRenderComponent] = useState(false)
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("scroll", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, [])

  const { isLargeScreen, isMobile } = useDeviceMedia()
  const [count, setCount] = useState(0)
  let mortgageValue = ""
  if (typeof window !== "undefined") {
    mortgageValue = sessionStorage.getItem("mortgage_repayments")
  }

  const negotiator = props.negotiator
  const negoImage = props.negoImage
  const qrImage =
    propertyInfo?.qr_code?.length > 0
      ? propertyInfo?.qr_code[0]?.validationQr
      : ""
  const permitNumber =
    propertyInfo?.qr_code?.length > 0
      ? propertyInfo?.qr_code[0]?.listingNumber
      : ""

   useEffect(()=>{
      const getId = document.querySelectorAll("#room_info");
      setCount(getId?.length?getId?.length:0)
   },[])

   const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const imagename="team.image.property_team_image"


  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const language_speaks =negotiator?.Languages_Spoken?.strapi_json_value?.length>0?
  negotiator?.Languages_Spoken?.strapi_json_value?.join(", "):""

  const negoUrl = negotiator?.slug? `${teamURL}${negotiator?.slug}/`:"/contact/"

  return (
    <div>
      <div className="property-info-wrapper">
        <Container>
          <div className="property-info">
            <div className="property-left-info">
              <p className="property-price">
                AED {numberFormat(propertyInfo?.price)}{" "}
                {propertyInfo?.search_type === "lettings"
                  ? propertyInfo?.price_qualifier?.toUpperCase()
                  : ""}
              </p>
              {propertyInfo?.search_type !== "lettings" && (
                <div className="estimate-mortgage-text">
                  <p>Estimated Mortgage AED {mortgageValue}/month.</p>
                  <button className="pre-app-link"  onClick={handleModal}>Get pre-approved</button>
                  <Modal
                show={show}
                // onHide={handleClose}
                backdrop="static"
                centered
                dialogClassName="modal-popup-form"      >
                <Modal.Body>
                  <div className="popup-form-wrapper">
                    <div className="close-modal" onClick={() => handleClose()}>
                      <i className="icon black-cancel-icon"></i>
                    </div>
                    <div>
                      <DefaultForm
                        fields={FormFields}
                        formTitle="Mortgage Enquiry"
                        sourceUrl={pageurl}
                        isPropertyMortgage
                      />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
                </div>
              )}
              {propertyInfo?.introtext&& propertyInfo?.status !== "Off Market" &&
                <div className="features-list_prop">
                  <h2 className="special-data">
                     {propertyInfo?.introtext}
                  </h2>
               
                </div>
                }
               {propertyInfo?.status !== "Off Market"&& 
                <h1 className="title">
                  {capitalize(propertyInfo?.title)} in {propertyInfo?.display_address}
                </h1>
                }
             
              <div className={`rooms-count count-${count}`}>
              {propertyInfo?.floorarea_min>0&& propertyInfo?.floorarea_min!==0&&
                <div className="room-info" id="room_info"> 
                  <p><i className="icon prop-building-icon"/></p>
                  <p className="text_info">BUA:</p>
                  <p className="result">{propertyInfo?.floorarea_min?.toLocaleString()} sq.ft</p>
                </div>
              }
                {propertyInfo?.extra?.plot_area_min>0  && propertyInfo?.extra?.plot_area_min !==0&&
                <div className="room-info" id="room_info">
                  <p><i className="icon prop-plot-icon"/></p>
                  <p className="text_info">Plot:</p>
                  <p className="result">{Math.floor(propertyInfo?.extra?.plot_area_min*43560)?.toLocaleString()} sq.ft</p>
                </div>
                }

                {propertyInfo?.bedroom>0&&propertyInfo?.bedroom!==0&&
                <div className="room-info" id="room_info">
                  <p><i className="icon property-bed"/></p>
                  <p className="text_info">Bedrooms:</p>
                  <p className="result">{propertyInfo?.bedroom}</p>
                </div>
                }
                {propertyInfo?.bathroom>0 && propertyInfo?.bathroom!==0 &&
                <div className="room-info" id="room_info">
                  <p><i className="icon property-bath"/></p>
                  <p className="text_info">Bathrooms:</p>
                  <p className="result">{propertyInfo?.bathroom }</p>
                </div>
                }
                {(propertyInfo?.search_type==="lettings" &&propertyInfo?.extra?.extrasField?.pNoOfCheqs)&&
                  <div className="room-info" id="room_info">
                    <p><i className="icon cheque-icon"/></p>
                    <p className="text_info">Number of Cheques:</p>
                    <p className="result">{propertyInfo?.extra?.extrasField?.pNoOfCheqs}</p>
                  </div>
                }

                {(propertyInfo?.search_type==="sales" &&propertyInfo?.extra?.completion_status)&&
                  <div className="room-info" id="room_info">
                    <p><i className="icon prop-completion-icon"/></p>
                    <p className="text_info">Completion Status:</p>
                    <p className="result">{propertyInfo?.extra?.completion_status}</p>
                  </div>
                }
                 {(propertyInfo?.search_type==="sales"&&propertyInfo?.extra?.payment_type)&&
                  <div className="room-info" id="room_info">
                    <p><i className="icon payment-icon"/></p>
                    <p className="text_info">Payment Method:</p>
                    <p className="result">{propertyInfo?.extra?.payment_type}</p>
                  </div>
                }
                
               
              </div>
               {propertyInfo?.status === "Off Market" &&
                <p className="off-market-desc">Get exclusive access to this property before it’s published on Property Finder, Bayut or Dubizzle. Get in touch now.</p>
               } 

              {
                propertyInfo?.long_description ? (
                <div className="description-block">
                  {propertyInfo?.long_description && (
                    <div className="property-long-description">
                      {parse(propertyInfo?.long_description)}
                    </div>
                  )}
                </div>
              )
              :propertyInfo?.description && propertyInfo?.status === "Off Market"&&(
                <div className="description-block">
                {propertyInfo?.description && (
                  <div className="property-long-description">
                    {parse(propertyInfo?.description)}
                  </div>
                )}
                </div>
              )
            }
              {propertyInfo?.accommodation_summary?.length > 0 && (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="features-details">
                    <h5>Features</h5>
                    <ul className="feature-list-items">
                      {propertyInfo?.accommodation_summary.map(feature => {
                        return <li key={feature} className="feature-list">{feature}</li>
                      })}
                      <li className="feature-list">
                        Property reference code: {propertyInfo?.crm_id}
                      </li>
                    </ul>
                  </div>
                </>
              )}
              {propertyInfo?.special?.length > 0 && (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="amenities-details">
                    <h5>Amenities</h5>
                    <ul className="amenities-list-items">
                      {propertyInfo?.special?.map(amenities => {
                        return (
                          <li className="aminities-contents" aria-label={amenities?amenities:""}>
                            <i className="icon tick-icon"></i>
                            <p className="feature-list">{amenities}</p>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </>
              )}

              {isLargeScreen && negotiator && (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="property-team-data">
                  {negoImage?.url&&
                  <Link to={negoUrl}>
                    <div className="image-section">
                      <GGFXImage
                        ImageSrc={negoImage}
                        altText={`${negotiator?.name}`}
                        imagetransforms={negotiator?.ggfx_results}
                        renderer="srcSet"
                        imagename={imagename}
                        strapiID={negotiator?.strapi_id}
                      />
                    </div>
                    </Link>
                    }
                    <Link to={negoUrl}>
                    <div className="property-team-info">
                      {props.name && <h5 className="name">{props.name}</h5>}
                      {negotiator?.designation && (
                        <p className="designation">{negotiator.designation}</p>
                      )}
                      {language_speaks && (
                        <p className="language-speak">
                          Speaks {language_speaks}
                        </p>
                      )}
                    </div>
                    </Link>
                   
                  </div>
                </>
              )}

              {renderComponent&&              
              propertyInfo?.latitude && propertyInfo?.longitude ? (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="property-map-component">
                    <h5>Location</h5>
                    <p className="address_map_property">
                      {propertyInfo.display_address}
                    </p>
                    <Map property_details_map_data={propertyInfo} />
                  </div>
                </>
              ) : null}

              {propertyInfo.department === "residential" &&
                propertyInfo.search_type === "sales" && (
                  <SimilarSoldProperties propertyInfo={propertyInfo} />
                )}

              {propertyInfo.search_type === "sales" && (
                <MortgageCalculator propertyInfo={propertyInfo} />
              )}

              {propertyInfo.department === "residential"&&
                <RentalYieldCalculator  data={propertyInfo}/>
              }

              {qrImage && (
                <>
                  <div className="property-horizontal-line"></div>
                  <div className="qr-code-module">
                    <div className="image-section">
                      <img loading="lazy" src={qrImage} alt="qr code" />
                    </div>
                    <div className="qr-content">

                    <div className="icon-text-holder">
                      <h5>DLD Permit Number:</h5>
                        
                          <div 
                          className="info-icon-module">
                            <i className="icon info-icon"/>
                            <span className="tri-icon"><i className="icon triangle-icon" /></span>
                            <div className={`show-when-hover`}>
                            
                              <p>
                              The Dubai Land Department strongly advises all customers and 
                              investors to only engage with real estate advertisements that 
                              feature the QR Code
                              </p>
                            </div>
                          </div>
                    </div>

                      
                      
                      <p>{permitNumber}</p>
                      <p className="qr-text">
                        This property listing has been reviewed and verified by Dubai Land Department
                      </p>
                    </div>
                  </div>
                </>
              )}

              <div className="property-horizontal-line"></div>
              <PropertyAreaCards propertyInfo={propertyInfo} />
            </div>
            {!isLargeScreen && (
              <div className="property-right-info">
                <PropertyContactModule
                  negotiator={negotiator}
                  negoImage={negoImage}
                  propertyInfo={propertyInfo}
                  image_url={props.image_url}
                  name={props.name}
                  phoneNum={props.phoneNum}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PropertyInformation
