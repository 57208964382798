import axios from "axios"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { numberFormat } from "../Common/utils"
import "./SimilarSoldProperties.scss"

const SimilarSoldProperties = ({ propertyInfo }) => {
  const [soldProperties, setSoldProperties] = useState(false)

  const token = process.env.GATSBY_STRAPI_FORM_TOKEN
  const strapi_url = process.env.GATSBY_STRAPI_SRC

  let data = JSON.stringify({
    area: propertyInfo.community
      ? propertyInfo.community
      : propertyInfo.area
      ? propertyInfo.area
      : "dubai",
  })

  // let config = {
  //   method: "post",
  //   maxBodyLength: Infinity,
  //   url: `${strapi_url}/api/avm/sold-properties`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },

  //   data: data,
  // }

  // useEffect(() => {
  //   axios
  //     .request(config)
  //     .then(response => {
  //       setSoldProperties(response.data)
  //       setTimeout(() => {
  //         if (
  //           typeof window !== "undefined" &&
  //           window.location.href.includes("mortgage-calculator")
  //         ) {
  //           const getId = document.getElementById("mortgage-calculator")
  //           getId?.scrollIntoView({ behavior: "smooth" })
  //         }
  //       }, 100)

  //       //   console.log(JSON.stringify(response.data))
  //     })
  //     .catch(error => {
  //       // console.log(error)
  //     })
  // }, [propertyInfo.area])


  const building = propertyInfo?.building?.length>0 ? propertyInfo?.building[0] : "Apartment"

  const minPrice = propertyInfo?.price - 200000
  const maxPrice = propertyInfo?.price + 200000

  // const isSameArea = propertyInfo?.area === propertyInfo?.community

  let apiUrl = `https://api.propertymonitor.com/pm/v1/pmiq?masterDevelopment=${propertyInfo?.community}&propertyType=${building}&minBeds=${propertyInfo?.bedroom}&maxBeds=${propertyInfo?.bedroom}&category=sale&minPrice=${minPrice}&maxPrice=${maxPrice}&limit=3`
  
  // if(isSameArea){
  //   apiUrl = `https://api.propertymonitor.com/pm/v1/pmiq?masterDevelopment=${propertyInfo?.community}&propertyType=${building}&minBeds=${propertyInfo?.bedroom}&maxBeds=${propertyInfo?.bedroom}&category=sale&minPrice=${minPrice}&maxPrice=${maxPrice}&limit=3`
  // }

  const fetchData = async(url) =>{
    try {
      const getData = await fetch(url,{
        headers:{
          "company-key": "c7a00562472d3d81f40a7b473f466e1f3ab7a09f",
          "x-api-key": "1h4b60rYMQ6UK3WYieHhX44evBAQoAmV1gxt2dfC",
          "Content-Type":"application/json"
        }
      });
      const response  = await getData.json()
      if(response?.data?.length>0){
        setSoldProperties(response.data)
      }
    } catch (error) {
      console.log("Sold properties endpoint error", error)
    }
  }

  useEffect(()=>{
    fetchData(apiUrl)
  },[])



  const { isMobile } = useDeviceMedia()

  return (
    <div className="similar-sold-wrapper">
      {soldProperties?.length > 0 && (
        <>
          <div className="property-horizontal-line"></div>
          <div className="sold-properties-content">
            <h5>Similar Sold Properties</h5>
            <div className="sold-container">
              {soldProperties?.length > 0 &&
                soldProperties.map((data, index) => {
                  return (
                    <div className="sold-list" key={data?.id}>
                      <div className="address-and-date">
                        <p className="address">{data.sub_loc_2}</p>
                        <p className="sold-date">
                          Sold at{" "}
                          {format(new Date(data.evidence_date), "MMM MM, yyyy")}
                        </p>
                      </div>
                      <div className="counts">
                        <div className="square-meter">
                          <span>
                            {!isMobile ? "Unit" : ""} {data.unit_bua_sqm}
                          </span>
                        </div>
                        <div className="price">
                          <span>
                            AED {numberFormat(data.total_sales_price)}
                          </span>
                        </div>
                        <div className="bedroom">
                          <i className="icon bed-icon" />
                          <span>{data.no_beds}</span>
                        </div>
                        <div className="area-size">
                          <i className="icon area-icon" />
                          <span>{data.unit_bua_sqft} sq ft</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>

            <p className="powered-by">Powered by Property Monitor</p>
          </div>
        </>
      )}
    </div>
  )
}

export default SimilarSoldProperties
